import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
const { Browser } = Plugins;

@Injectable()
export class BrowserService {
	constructor() { }

	open(externalUrl) {
	 
		Browser.open({
			url: externalUrl,
			toolbarColor: '#050b29'
		})
					 
	}

	openExternal(externalUrl){
		Browser.open({ url: externalUrl, windowName: "_system"});
 
	}
}
